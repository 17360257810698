import React from 'react';
import { Button, Result } from 'antd';

function NoUserFound() {
  const handleRetry = () => {
    window.location.reload();
  };

  const handleClose = () => {
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.close();
    }
  };

  return (
    <div style={styles.container}>
      <Result
        status="error"
        title="User Not Found"
        subTitle="We couldn't retrieve your account information. Please try again."
        extra={[
          <Button key="retry" type="primary" onClick={handleRetry}>
            Retry
          </Button>,
          <Button key="close" type="default" onClick={handleClose}>
            Close App
          </Button>,
        ]}
      />
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f5f5f5',
  },
};

export default NoUserFound;
