import React from 'react';
import { Spin, Typography } from 'antd';
import 'antd/dist/reset.css';

const { Text } = Typography;
const Loader = () => {
  return (
    <div style={loaderContainerStyle}>
      <Spin
        size="large"
        tip="Loading..."
        style={customSpinnerStyle}
      />
      <Text strong
        style={{
          color: '#999', position: 'fixed', bottom: 20, textTransform: 'uppercase'
        }}>
        Powered By Sewawi Techs
      </Text>
    </div>
  );
};

const loaderContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100vh',
  width: '100vw',
  backgroundColor: '#f5f5f5',
  position: 'absolute',
  top: 0,
  left: 0,
};

const customSpinnerStyle = {
  '--antd-wave-shadow-color': '#990011',
};

export default Loader;
