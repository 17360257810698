import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { notification } from 'antd';
import { useUser } from './UserContext';

const FavoritesContext = createContext();
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export function FavoritesProvider({ children }) {
    const { userId, user } = useUser();
    const [favorites, setFavorites] = useState([]);
    const [isFetchingFavorites, setIsFetchingFavorites] = useState(true);
    const [isUpdatingFavorite, setIsUpdatingFavorite] = useState(false);

    const fetchData = async () => {
        setIsFetchingFavorites(true);
        try {
            const response = await axios.get(`${apiBaseUrl}favorites/${userId}`);
            setFavorites(response.data.favorites || []);
        } catch (error) {
            console.error('Error fetching featured listings:', error);
        } finally {
            setIsFetchingFavorites(false);
        }
    };

    useEffect(() => {
        if (userId) {
            fetchData();
        }
    }, [userId]);

    const addFavorite = async (listingId) => {
        setIsUpdatingFavorite(true);
        try {
            await axios.post(`${apiBaseUrl}favorites/add/${listingId}`, {
                userInfo: {
                    tg_id: userId,
                    tg_first_name: user.first_name,
                    tg_last_name: user.last_name,
                    tg_username: user.username,
                }
            });

            fetchData();
            notification.success({
                message: 'Favorite Added',
                description: 'The listing has been added to your favorites.',
            });
        } catch (error) {
            console.error('Error adding favorite listing:', error);
            notification.error({
                message: 'Add Favorite Error',
                description: 'There was an error adding the listing to your favorites.',
            });
        } finally {
            setIsUpdatingFavorite(false);
        }
    };

    const removeFavorite = async (listingId) => {
        setIsUpdatingFavorite(true);
        try {
            await axios.post(`${apiBaseUrl}favorites/remove/${listingId}`, {
                userInfo: {
                    tg_id: userId,
                    tg_first_name: user.first_name,
                    tg_last_name: user.last_name,
                    tg_username: user.username,
                }
            });

            fetchData();
            notification.success({
                message: 'Favorite Removed',
                description: 'The listing has been removed from your favorites.',
            });
        } catch (error) {
            console.error('Error removing favorite listing:', error);
            notification.error({
                message: 'Remove Favorite Error',
                description: 'There was an error removing the listing from your favorites.',
            });
        } finally {
            setIsUpdatingFavorite(false);
        }
    };

    return (
        <FavoritesContext.Provider value={{ favorites, addFavorite, removeFavorite, isFetchingFavorites, isUpdatingFavorite }}>
            {children}
        </FavoritesContext.Provider>
    );
}

export function useFavorites() {
    return useContext(FavoritesContext);
}