// import React, { Suspense, useState, useEffect } from 'react';
// import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import './App.css';
// import Loader from './components/Loader';
// import ProtectedRoute from './components/ProtectedRoute';
// import { useUser } from './context/UserContext';
// import NoUserFound from './pages/NoUserFound';
// import NotFound from './pages/NotFound'; 

// const Home = React.lazy(() => import('./pages/home/Home'));
// const CreateListing = React.lazy(() => import('./pages/create-listing/CreateListing'));
// const ListingDetails = React.lazy(() => import('./pages/listing-details/ListingDetails'));
// const MyListings = React.lazy(() => import('./pages/MyListings'));
// const EditListing = React.lazy(() => import('./pages/edit-listing/EditListing'));
// const Category = React.lazy(() => import('./pages/category/Category'));
// const SavedListings = React.lazy(() => import('./pages/SavedListings'));

// function App() {
//   const { userId } = useUser();
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const tg = window.Telegram.WebApp;
//     tg.ready();
//     tg.expand();

//   }, []);

//   useEffect(() => {
//     if (userId !== null) {
//       setLoading(false);
//     }
//   }, [userId]);

//   if (loading) {
//     return <Loader />;
//   }

//   return (
//     <Router>
//       <div style={{ backgroundColor: '#f5f5f5', overflow: 'scroll', height: '100vh' }}>
//         <Suspense fallback={<Loader />}>
//           <Routes>
//             <Route path="/" element={<ProtectedRoute element={Home} />} />
//             <Route path="/no-user-found" element={<NoUserFound />} />
//             <Route path="/listing/:listingId" element={<ProtectedRoute element={ListingDetails} />} />
//             <Route path="/create-listing" element={<ProtectedRoute element={CreateListing} />} />
//             <Route path="/my-listings" element={<ProtectedRoute element={MyListings} />} />
//             <Route path="/edit-listing/:listingId" element={<ProtectedRoute element={EditListing} />} />
//             <Route path="/favorites" element={<ProtectedRoute element={SavedListings} />} />
//             <Route path="/category/:categoryName" element={<ProtectedRoute element={Category} />} />
//             <Route path="*" element={<NotFound />} />
//           </Routes>
//         </Suspense>
//         <ToastContainer position="top-center" />
//       </div>
//     </Router>
//   );
// }

// export default App;

import React, { useState, useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Loader from './components/Loader';
import ProtectedRoute from './components/ProtectedRoute';
import { useUser } from './context/UserContext';
import NoUserFound from './pages/NoUserFound';
import NotFound from './pages/NotFound';

const Home = React.lazy(() => import('./pages/home/Home'));
const CreateListing = React.lazy(() => import('./pages/create-listing/CreateListing'));
const ListingDetails = React.lazy(() => import('./pages/listing-details/ListingDetails'));
const MyListings = React.lazy(() => import('./pages/MyListings'));
const EditListing = React.lazy(() => import('./pages/edit-listing/EditListing'));
const Category = React.lazy(() => import('./pages/category/Category'));
const SavedListings = React.lazy(() => import('./pages/SavedListings'));

function App() {
  const { userId } = useUser();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const tg = window.Telegram.WebApp;
    tg.ready();
    tg.expand();

    try {
      const startParam = tg.initDataUnsafe.start_param || '';
      const match = startParam.match(/listingId(.+)/);
      const id = match ? match[1] : '';

      if (id) {
        navigate(`/listing/${id}`); 
      }
    } catch (error) {
      console.error('Error extracting start_param:', error);
    }
  }, []);

  useEffect(() => {
    if (userId !== null) {
      setLoading(false);
    }
  }, [userId]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div style={{ backgroundColor: '#f5f5f5', overflow: 'scroll', height: '100vh' }}>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<ProtectedRoute element={Home} />} />
          <Route path="/no-user-found" element={<NoUserFound />} />
          <Route path="/listing/:listingId" element={<ProtectedRoute element={ListingDetails} />} />
          <Route path="/create-listing" element={<ProtectedRoute element={CreateListing} />} />
          <Route path="/my-listings" element={<ProtectedRoute element={MyListings} />} />
          <Route path="/edit-listing/:listingId" element={<ProtectedRoute element={EditListing} />} />
          <Route path="/favorites" element={<ProtectedRoute element={SavedListings} />} />
          <Route path="/category/:categoryName" element={<ProtectedRoute element={Category} />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
      <ToastContainer position="top-center" />
    </div>
  );
}

export default function AppWithRouter() {
  return (
    <Router>
      <App />
    </Router>
  );
}
